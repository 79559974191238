<template>
  <form class="form w-100 mt-20 pt-lg-10" novalidate="novalidate" action="#">
    <a class="btn btn-light-primary" href="#" @click.prevent="signIn">
      <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" class="h-20px me-2" />
      Sign in with Google
    </a>
  </form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      message: 'auth/message',
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    async signIn() {
      const googleUser = await this.$gAuth.signIn();
      const profileInfo = googleUser.getBasicProfile();
      const user = {
        email: profileInfo.getEmail(),
        first_name: profileInfo.getGivenName(),
        last_name: profileInfo.getFamilyName(),
        avatar_url: profileInfo.getImageUrl(),
        uid: googleUser.getId(),
      };

      await this.login(user);
      if (this.isAuthenticated) {
        await this.$router.push({ name: 'dashboard' });
      }
    },
  },
};
</script>
